
.contact .topTitle{
	margin: 0.5rem 0 0.2rem 0.5rem;
	color: rgb(76,117,123);
	font-weight: 600;
	font-size: 0.3rem;
}
.tel:hover,
.emailas:hover {
  color: #ff9900;
  text-decoration: underline;
}

.contact .bg {
    background-color: rgb(232,244,244);
    
}
.contact .bg2 {
    background-color: rgb(135, 200, 210);
}
.contact .topCont{
	color: #000;
	margin: 0 0 0.6rem 0.5rem;
	font-size: 0.16rem;
	width: 50%;
}
.contact .center{
	padding: 3% 0 5% 5%;
	color: #000;
	font-size: 0.15rem;
}
.contact .content{
	width: 40%;
	margin-right: 6%;
}
.contact .lis{
	border-bottom: 2px solid #555;
	padding-bottom: 0.1rem;
	margin-top: 0.3rem;
}
.contact .iput{
	width: 100%;
	background-color: rgb(232,244,244);
	font-size: 16px;
	font-family: JosefinSans-Regular;
}
.contact textarea.iput {
	border: none;
	outline: none;
	resize:none;
}
.contact .sub{
	margin-top: 0.3rem;
	color: #fff;
	background-color: rgb(63,117,123);
	padding: 0.11rem 0;
	width: 36%;
	border-radius: 0.1rem;
	text-align: center;
	cursor: pointer;
}
.contact .one{
	font-weight: 600;
    margin-bottom: 0.2rem;
    margin-top: 0.2rem;
}
.contact .tel {
    color: rgb(67,117,123);font-weight: 600;
}
.contact .emailas {
    color: rgb(67,117,123);font-weight: 600;
}

.contact .secctBtn {
	flex-direction: column;
	padding: 5% 0;
}

.contact .btns {
	font-size: 0.15rem;
	background-color: rgb(63, 117, 123);
	margin-top: 0.3rem;
	padding: 0.1rem 0.38rem;
	border-radius: 0.1rem;
	cursor: pointer;
}
.contact .title{
	font-size: 0.25rem;
	font-weight: 600;
}

@media screen and (max-width:950px){
	.contact .topTitle{
		font-size: 0.4rem;
	}
	.contact .topCont{
		font-size: 0.2rem;
		width: 70%;
	}
	.contact .center{
		font-size: 0.2rem;
	}
	.contact .content{
		width: 50%;
	}
}
@media screen and (max-width:560px){
	.contact .topTitle{
		margin: 0.6rem 0 0.5rem 0.6rem;
		font-size: 0.84rem;
		    font-family: Arial, sans-serif;
	}
	.contact .topCont{
		font-size: 0.28rem;
		width: 76%;
		line-height: 0.34rem;
	}
	.contact .center{
		flex-direction: column;
		align-items: flex-start;
		padding: 3% 0.56rem;
	}
	.contact .content{
		width: 88%;
		margin: 0;
		font-size: 0.28rem;
	}
	.contact .lis{
		margin: 0.6rem 0;
		padding-bottom: 0.24rem;
	}
	.contact .sub{
		margin-top: 0.4rem;
		line-height: 0.5rem;
		width: 100%;
		font-weight: 600;
		border-radius: 0.26rem;
	}
	.contact .care{
		font-size: 0.28rem;
		margin: 0.7rem 0;
		line-height: 0.34rem;
	}
	.contact .one{
		padding: 0.2rem 0;
	}
	.contact .secctBtn {
		padding: 15% 0;
	}
	.contact .title{
		font-size: 0.7rem;
		width: 70%;
		line-height:0.9rem;
		text-align: center;
		font-weight: bold;
		    font-family: Arial, sans-serif;
	}
	.contact .btns {
		font-size: 0.3rem;
		margin-top: 0.5rem;
		font-weight: 600;
		width: 80%;
		text-align: center;
		padding: 0.27rem 0;
		border-radius: 0.3rem;
	}
}
