.FreshenerScreen select {
	background-color: rgb(232, 244, 244);
	width: 100%;
}

.FreshenerScreen .title {
	color: rgb(67, 117, 123);
	font-size: 0.33rem;
	margin: 0.3rem 0 0.2rem 0;
	font-weight: 600;
}

.FreshenerScreen .cont {
	color: #000;
	width: 50%;
	font-size: 0.14rem;
	line-height: 0.2rem;
}

.FreshenerScreen .btn {
	background-color: rgb(255, 192, 8);
	padding: 0.12rem 0.34rem;
	font-size: 0.15rem;
	border-radius: 0.13rem;
	cursor: pointer;
}

.FreshenerScreen .secction {
	padding-bottom: 0.7rem;
}

.FreshenerScreen .content {
	display: flex;
	color: rgb(67, 117, 123);
	padding: 0.7rem 0;
}

.FreshenerScreen .left {
	flex: 2;
	/* font-style: oblique; */
}

.FreshenerScreen .right,
.FreshenerScreen .boxSelect,
.FreshenerScreen .back,
.FreshenerScreen .add,
.FreshenerScreen .lis {
	flex: 1;
	/* font-style: oblique; */
}

.FreshenerScreen .left,
.FreshenerScreen .minLeft {
	margin-right: 0.3rem;
}

.FreshenerScreen .tips {
	color: #000;
	margin: 0.3rem 0 0.06rem 0;
	font-size: 0.16rem;
}

.FreshenerScreen .add {
	background-color: rgb(253, 191, 8);
	color: #fff;
	font-weight: 600;
	text-align: center;
	font-size: 0.2rem;
	padding: 0.13rem 0;
	border-radius: 0.14rem;
	cursor: pointer;
}

.FreshenerScreen .backts {
	display:  block;
	background-color: rgb(67, 117, 123);
	color: #fff;
	font-weight: 600;
	text-align: center;
	font-size: 0.2rem;
	padding: 0.13rem 0;
	border-radius: 0.14rem;
	cursor: pointer;
}

.FreshenerScreen .right_title {
	font-size: 0.34rem;
	font-weight: 600;
	margin-bottom: 0.3rem;
}

.FreshenerScreen .scent {
	font-size: 0.16rem;
	color: #000;
	font-weight: 600;
	line-height: 0.2rem;
	margin-bottom: 0.2rem;
}

.FreshenerScreen .subtotal {
	font-size: 0.16rem;
	color: #000;
	font-weight: 600;
	border-bottom: 2px solid #555;
	padding-bottom: 0.2rem;
	margin-bottom: 0.2rem;
	line-height: 0.22rem;
}

.FreshenerScreen .total,
.FreshenerScreen .vat {
	font-size: 0.16rem;
	color: #000;
	line-height: 0.18rem;
}

.FreshenerScreen .vat {
	margin-top: 0.05rem;
}

.FreshenerScreen .money {
	font-weight: 600;
	color: rgb(67, 117, 123);
	font-size: 0.2rem;
}

.FreshenerScreen .cs-list-item {
	margin-bottom: 0.2rem;
}

.FreshenerScreen .cs-list-item-tips {
	color: #6c7177;
	margin-top: 0.05rem;
}

.FreshenerScreen .cs-list-item-label {
	color: #383f50;
	font-weight: 500;
	margin-bottom: 0.05rem;
}

.FreshenerScreen .review {
	color: #fff;
	background-color: rgb(255, 192, 8);
	text-align: center;
	font-size: 0.2rem;
	margin: 0.2rem 0;
	padding: 0.13rem 0;
	border-radius: 0.14rem;
	font-weight: 600;
	cursor: pointer;
}

.FreshenerScreen .save {
	color: #fff;
	text-align: center;
	background-color: rgb(67, 117, 123);
	padding: 0.13rem 0;
	font-size: 0.2rem;
	font-weight: 600;
	border-radius: 0.14rem;
	cursor: pointer;
	margin-top: .2rem;
}

.FreshenerScreen .selectName {
	color: #000;
	font-size: 0.16rem;
	font-weight: 600;
	margin-right: 0.1rem;
}

.FreshenerScreen .downPutOne {
	/*border-bottom: 1px solid #000;*/
	flex: 1;
	/* font-style: oblique; */
	color: #999;
	/* margin: 0.06rem 0; */
	height: auto;
}

.FreshenerScreen .product-summary-select {
	margin-bottom: 15px;
}

.FreshenerScreen .left_title {
	font-size: 0.24rem;
	font-weight: 600;
	margin-bottom: 0.3rem;
}

.FreshenerScreen .lis_title {
	width: calc(100% - 1rem);
	display: flex;
	color: #000;
	border-bottom: 1px solid #000;
	padding-bottom: 0.08rem;
	font-weight: bold;
	text-align: center;
}

.FreshenerScreen .lis_title div {
	flex: 1 1 25%;
}

.FreshenerScreen .list_cselect {
	/* font-style: oblique; */
	color: #666;
	border-bottom: 1px solid #000;
	width: 1rem;
	font-size: 0.16rem;
}

.FreshenerScreen .colorBox {
	width: 0.18rem;
	height: 0.18rem;
	background-color: rgb(105, 200, 208);
	border-radius: 0.04rem;
	margin-right: 0.08rem;
}

.FreshenerScreen .iptQuantity {
	background-color: rgb(232, 244, 244);
	border-bottom: 1px solid #000;
	/* font-style: oblique; */
	font-size: 0.16rem;
	width: 1rem;
	height: 100%;
}

.FreshenerScreen .selectLi {
	width: 25%;
	flex: 1 1 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.2rem 0;
    font-size: 0.16rem;
    color: #000;
	position: relative;
	height: 40px;
}

.FreshenerScreen .selectLi .downPutOne {
	font-size: 0.16rem;
}

.FreshenerScreen .active {
	color: rgb(255, 192, 8);
}

.FreshenerScreen .delete {
	color: #fff;
	background-color: rgb(67, 117, 123);
	font-size: 0.2rem;
	font-weight: 600;
	padding: 0.06rem 0.1rem;
	border-radius: 0.08rem;
	cursor: pointer;
}

.FreshenerScreen .scent-select {
	cursor: pointer;
}

.FreshenerScreen .color-select {
	cursor: pointer;
	flex-wrap: nowrap;
	margin-right: 0.4rem;
	/* width: 180%; */
	background: inherit;
}

.FreshenerScreen .deleteBox {
	margin-left: 0.3rem;
}
.FreshenerScreen .limit-tip {
	width: 100%;
	text-align: center;
	position: absolute;
	left: 50%;
	bottom: -0.25rem;
	color: red;
	font-size: 0.12rem;
	transform: translate(-50%, 0);
	/* display: none; */
}
.FreshenerScreen .layui-form-select .layui-input {
	background-color: transparent;
}

@media screen and (max-width:1070px) {
	.FreshenerScreen .content {
		flex-direction: column;
	}



	.FreshenerScreen .right {
		margin-top: 0.5rem;
	}

	.FreshenerScreen .cont {
		width: 90%;
	}

	.FreshenerScreen .secction {
		flex-direction: column;
		align-items: flex-start;
	}

	.FreshenerScreen .btn {
		margin: 0.2rem auto 0 auto;
		font-size: 0.2rem;
		font-weight: 600;
		width: 100%;
		text-align: center;
		padding: 0.18rem 0;
		border-radius: 0.2rem;
        font-family: Arial, sans-serif;
	}
}


@media screen and (max-width:720px) {

	.FreshenerScreen .list_content {
		flex-direction: column;
	}

	.FreshenerScreen .deleteBox {
		display: flex;
		justify-content: flex-end;
	}
	.FreshenerScreen .scent-select {
		margin-right: 0.1rem;
	}

	.FreshenerScreen .color-select {
		margin-right: 0.1rem;
	}
}

@media screen and (max-width:640px) {
	.FreshenerScreen .selectLi {
		margin: 0;
	}

	.FreshenerScreen .lis {
		flex-direction: column;
		margin: 0;
	}

	.FreshenerScreen .left,
	.FreshenerScreen .right {
		flex: 1;
	}

	.FreshenerScreen .minLeft,
	.FreshenerScreen .minRight {
		width: 100%;
		margin: 0.16rem 0;
	}

	.FreshenerScreen .boxSelect {
		width: 100%;
	}

	.FreshenerScreen .minLeft {
		margin-right: 0;
	}

	.FreshenerScreen .toGo {
		margin-top: 0.2rem;
	}
}

@media screen and (max-width:560px) {

	.FreshenerScreen .title {
		font-size: 0.6rem;
		line-height: 0.7rem;
		width: 100%;
		    font-family: Arial, sans-serif;
    text-align: center;
    font-weight: bold;
	}

	.FreshenerScreen .cont {
		font-size: 0.24rem;
		line-height: 0.28rem;
		display: none;
	}

	.FreshenerScreen .left_title {
		font-size: 0.3rem;
		line-height: 0.44rem;
	}

	.FreshenerScreen .minLeft,
	.FreshenerScreen .minRight {
		font-size: 0.2rem;
	}

	.FreshenerScreen .boxs,
	.FreshenerScreen .dui {
		width: 0.48rem;
		height: 0.48rem;
		border-radius: 0.06rem;
		background-color: rgb(105, 200, 208);
		border: 1px solid #000;
		margin-right: 0.2rem;
		cursor: pointer;
	}

	.FreshenerScreen .toGo {
		font-size: 0.2rem;
	}

	.FreshenerScreen .btnUl {
		flex-direction: column;
	}

	.FreshenerScreen .back,
	.FreshenerScreen .add {
		width: 100%;
		margin: 0.2rem 0;
		padding: 0.2rem 0;
		font-size: 0.24rem;
		font-weight: 600;
		border-radius: 0.24rem;
	}

	.FreshenerScreen .scent,
	.FreshenerScreen .subtotal,
	.FreshenerScreen .total,
	.FreshenerScreen .vat {
		font-size: 0.2rem;
		line-height: 0.24rem;
	}

	.FreshenerScreen .money {
		font-size: 0.3rem;
	}

	.FreshenerScreen .review,
	.FreshenerScreen .save {
		font-size: 0.25rem;
		padding: 0.2rem 0;
		border-radius: 0.2rem;
	}



	.FreshenerScreen .color-select {
		margin-right: 0.1rem;
	}
		.FreshenerScreen .btn {
		margin: 0.2rem auto 0 auto;
		font-size: 0.25rem;
		font-weight: 600;
		width: 100%;
		text-align: center;
		padding: 0.18rem 0;
		border-radius: 0.2rem;
        font-family: Arial, sans-serif;
	}
	.downPutOne {
	cursor: pointer;
	background-color: rgb(232, 244, 244);
}

	.FreshenerScreen .scent-select {
		margin-right: 0.1rem;
		margin-top: 0px;
	}
	.FreshenerScreen .selectLi .downPutOne flex-between color-select {
	font-size: 0.16rem;
	margin-top: 0px;
}

}
