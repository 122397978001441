/* SelectItem.css */

/* Styling for the greenCard container */
div.greenCard {
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px !important;
  position: relative;
  width: 100%;
}


/* Styling for the title */
.SelTitle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: #3F757B;
  font-size: 0.25rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  font-family: Arial, sans-serif;
  letter-spacing: inherit;
}

/* Styling for the error icon */
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: #83c2c8;
  font-weight: bold;
  font-size: 22px;
}

/* Styling for the select dropdowns */
.downPutOne {
  /* background: #ffffff; */
  border-radius: 2px;
  color: #6a6a6a;
  font-size: 16px;
  height: 40px;
  /* margin-bottom: 8px; */
  outline: none;
  padding-left: 12px;
  width: 100%;
}

/* Styling for the select dropdowns when hovering over them */
.downPutOne:hover {
  border-color: #45bc73;
}

/* Styling for the quantity input */
.quantity-input {
  font-size: 0.14rem;
  width: auto;
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  height: 36px;
  user-select: none;
  overflow: hidden;
}

.quantity-btn {
  align-items: center;
  border-radius: 2px;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 24px;
  font-size: 0.16rem;
  background-color: white;
  cursor: pointer;
  font-weight: 400;
  color: #333;
  padding: 0 0.18rem;
}

.quantity-input input {
  width: 0.3rem;
  text-align: center;
  border-style: solid;
  border-color: #e5e5e5;
  border-width: 0 1px;
  padding: 0 0.04rem;
  height: 100%;
  background: white;
}

.quantity-btn:hover {
  background-color: #e2e2e2;
}

/* Styling for the Add to Basket button */
.SelectItem.greenCard .basket-btn {
  background-color: hsl(45deg 100% 51%);
  border-radius: 0.06rem;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  line-height: 1;
  padding: 0.14rem;
}

.price {
  color: #FFC008;
  margin-left: 0.2rem;
  font-weight: bold;
  font-size: 18px;
}

.basket-btn:hover {
  background-color: #2b9057;
}

.disabled {
  background-color: #c2c2c2;
  cursor: not-allowed;
}

/* Styling for the stock status */
.stock-pc {
  align-items: center;
  display: flex;
}

.stock-mb {
  align-items: center;
  display: none;
}

/* Styling for the stock status when hovering over it */
.stock-pc:hover {
  background-color: #e2e2e2;
}

/* Styling for the stock status when hovering over it on mobile */
.stock-mb:hover {
  background-color: #e2e2e2;
}

.goods-attr-label {
  font-size: 0.15rem;
  width: 0.7rem;
}

.goods-actions {
  border-bottom: 2px solid #111111;
  padding: 0.1rem 0 0.2rem;
  margin-bottom: 0.16rem;
}

@media screen and (max-width: 540px) {
  /* Styling for mobile devices */
  .SelectItem {
    padding: 12px;
  }
    .tanjia {
    margin-top: 95px;
    margin-left: -200px;
    text-align: center;
    display: flex;
  }

  .stock-pc {
    display: none;
    font-size: 0.2rem;
  }

  .stock-mb {
    display: flex;
  }
  .SelTitle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.TextYellowBtn.btn {
  font-size: 18px;
  padding: 10px 40px;
  margin-left: 0.3rem;
}

  .goods-attr-label {
    font-size: 0.2rem;
    width: 1rem;
  }

  .quantity-input .quantity-btn {
    font-size: 0.3rem;
    padding: 0 0.25rem;
    height: 100%;
  }

  .quantity-input {
    font-size: 0.35rem;
  }
  .quantity-input input {
    width: 0.3rem;
    text-align: center;
    font-size: 16px; /* add this line to set the font size */
    padding: 0 0.12rem;
  }
  .goods-actions {
    margin-bottom: 0.3rem;
  }
  .SelectItem.greenCard .basket-btn {
    padding: 0.2rem;
  }
}
