.container {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  max-width: 1200px;
  margin: 0 auto;
}

.left {
  display: flex;
  align-items: flex-start;
  /*flex-direction: column;*/
  gap: 10px;
  width: 500px;
}

.leftActions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.right {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 20px;
}

.rightTop {
  display: flex;
  flex-wrap: wrap;
  /*justify-content: space-between;*/
  align-items: center;
  gap: 10px;
}

.rightTopBtns {
  display: flex;
  gap: 10px;
}

.previewImgWrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  flex: 1 1 0%;
}

.previewModal {
  /* max-width: 56rem !important; */
  width: 100% !important;
}

.previewContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.previewImg {
  width: 300px;
  max-width: 100%;
}

.previewImgInner {
}

.aspectActive::after {
  content: '';
  height: 1px;
  background-color: #ffc008;
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 6px;
}

.rightTopFirst {
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
}

.rightTopSecond {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rightTopThird {
  display: flex;
  /*justify-content: flex-end;*/
  width: 100%;
}

.title {
  width: 200px;
}

.rightBottom {
  position: relative;
  /* flex: 1; */
  /* border: 3px dashed red; */ /* 这一行被注释掉或移除 */
  border-radius: 10px;
  margin-top: 20px;
  overflow: hidden;
}

.actionCard {
  border: 1px solid #e1e1e1;
  padding: 20px;
  width: 100%;
  border-radius: 0.06rem;
  margin-bottom: 10px;
}

.actionCardTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.actionCardSubTitle {
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0;
}

.formatActions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fullwidth {
  width: 100%;
}

.textPanelHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textPanelContent {
  margin-bottom: 20px;
  display: flex;
  overflow-x: auto;
  max-width: 340px;
  gap: 10px;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-width: thin;
  font-family: Aleo, serif;
}

.textPanelFont {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  padding: 10px;
  width: 100px;
  height: 80px;
  flex-shrink: 0;
  text-align: center;
  font-size: 0.2rem;
  cursor: pointer;
}

.fontBottom {
  display: flex;
  align-items: center;
}

.fontInput {
  width: 100px;
  margin-right: 10px;
}

.activeTextPanelFont {
  border-color: #ffc008;
}

.circle {
  position: absolute;
  left: 185px;
  top: 105px;
  overflow: hidden;
  z-index: 999;
}

.pin {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px dashed #333;
  border-radius: 50%;
  z-index: 99;
  background-color: white;
}

.warning {
  position: absolute;
  left: 107px;
  top: 255px;
  overflow: hidden;
  z-index: 999;
  font-family: Arial, serif;
  font-size: 13px;
  text-shadow: 0 0 3px white;
  height: 20px;
}

.editText {
  border: 1px solid #e1e1e1;
  border-radius: 0.06rem;
  padding: 6px 8px;
}

@media screen and (max-width: 720px) {
  .container {
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

  .right {
    margin: 20px 0;
  }

  .left {
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
  }

  .leftActions {
    flex-direction: row;
  }

  .rightTop {
    flex-wrap: wrap;
    gap: 10px;
  }

  .title {
    width: 180px;
  }

  .textPanelFont {
    font-size: 0.3rem;
  }

  .previewContainer {
    /* flex-direction: column; */
  }
}

.dom-hidden {
  display: none;
}

.toolWrap {
  position: absolute;
  background: rgb(255, 192, 8);
  border-radius: 0.06rem;
  color: white;
  font-size: 13px;
  white-space: nowrap;
  font-weight: bold;
  will-change: transform;
  transform: translate(-50%, 0px);
}
