.accountScreen > .maxWidth {
  padding: 0 0.5rem;
}

.accountScreen {
  top: 60px; /* Change this value to match the height of your header */
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow-y: auto;
}

.accountScreen .left {
  background-color: rgb(135, 202, 210);
  border-radius: 0.06rem;
  margin: 0 0 0.5rem 0;
  width: 28%;
  padding: 0.1rem 0.05rem;
  font-weight: 600;
  line-height: 0.24rem;
  font-size: 0.2rem;
  flex: 0 0 28%;
}

.accountScreen .left div {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 0.16rem !important;
  /*margin: 0 0 0.2rem 0;*/
  margin: 0;
  padding: 0.1rem 0.2rem;
  border-radius: 0.05rem;
}

.accountScreen .left div:first-child {
  background-color:  rgb(232,244,244);
  font-size: 0.18rem !important;
  margin-bottom: 0.12rem;
}

.accountScreen .left div:last-child {
  margin-bottom: 0;
}

.accountScreen .left div:not(:first-child) {
  margin-left: 0.2rem;
}

.accountScreen .left div:not(:first-child):hover {
  background-color: #fff;
  color: #000;
}

.accountScreen .title {
  color: #000;
  font-weight: 600;
}

.accountScreen .name {
  margin-top: 0.4rem;
  margin-bottom: 0.26rem;
}

@media screen and (max-width: 900px) {
  .accountScreen .flex-between {
    flex-direction: column;
  }

  .accountScreen .left {
    width: 100%;
    font-size: 0.16rem;
    line-height: 0.3rem;
    flex: 0 0 100%;
  }

  .accountScreen .left > div {
    font-size: 0.22rem !important;
  }

  .accountScreen .left div:first-child {
    font-size: 0.25rem !important;
  }

  .accountScreen .right {
    width: 100%;
    flex: 0 0 100%;
  }
}
