/* .ShippingScreen {
    color: black;
} */
.ShippingScreen {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    color: #55595c;
    padding: 0 .5rem;
}
.ShippingScreen legend {
    font-size: .5rem;
    margin-top: 1rem;
}
.ShippingScreen .TextGreenBtn {
    width: 1rem;
    height: .3rem;
    padding: 0;
    border-radius: .08rem;
    margin: .1rem 0;
    /* background: #1a1a1a;
    color: white; */
}
.ShippingScreen .nav span {
    display: inline-block;
    width: 50%;
    padding: .2rem 0;
    border-top: 1px solid #eeeeee;
    /* box-sizing: border-box; */
}
.ShippingScreen .nav .active {
    position: relative;
    color: #FEC107;
    border-top-color: #FEC107;
}
.ShippingScreen .cart-logo {
    width: .2rem;
    height: .2rem;
    position: absolute;
    top: -0.1rem;
    right: 0rem;
}
.ShippingScreen img {
    width: 100%;
    height: 100%;
}
.ShippingScreen a {
    color: inherit;
}
.ShippingScreen .col , .ShippingScreen .col-md-4{
    align-self: center;
}

.ShippingScreen .list-group-item p {
    margin-top: 0;
    margin: .16rem 0;
    letter-spacing: 1px;
    font-weight: 200;
    font-size: .14rem;
    color: rgb(85, 89, 92);
    /* margin-top: .16rem; */

}
.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0;
}
.list-group-flush>.list-group-item {
    border-width: 0 0 1px;
}
.col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.ShippingScreen .TextGreenBtn.PayBtn {
    width: 2.5rem;
    height: .6rem;
    font-size: .24rem;
}
.ShippingScreen .list-group-item.no-border {
    border:  none;
}
.ShippingScreen .list-group-item .row {
    margin-left: 0;
    margin-right: 0;
}

@media screen and (max-width:1070px) {
    .ShippingScreen .row.PayOrder {
        display: block;
    }
    .ShippingScreen .row.PayOrder .col-md-8, 
    .ShippingScreen .row.PayOrder .col-md-4{
        width: 100%;
        max-width: 100%;
    }
    .ShippingScreen .TextGreenBtn {
        display: block;
        margin:  0 auto;
    }
}

.apply_btn{
    border: 1px solid yellow;
    border-radius: 16px;
    padding: 4px 18px;
    transition: .3s;
}
.apply_btn:hover{
    background-color: yellow;
}
.toasts_success{
    color: #fff;
}

@media only screen and (max-width: 540px) {
  .ShippingScreen .nav .active {
    position: relative;
    color: #FEC107;
    border-top-color: #FEC107;
    font-size: 2.2em;
    border-top-width: 4px;
    margin-top: 0.7rem;
}

.ShippingScreen .nav span {
    display: inline-block;
    width: 50%;
    padding: .2rem 0;
    font-size: 2.2em;
    border-top-width: 4px;
    margin-top: 0.7rem;
    border-top: 4px solid #eeeeee;
    /* box-sizing: border-box; */
}
.ShippingScreen .cart-logo {
    width: .6rem;
    height: .6rem;
    position: absolute;
    top: -0.3rem;
    right: 0rem;
}
.ShippingScreen legend {
  font-size: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; /* Use a nicer font family */
}
.ShippingScreen .TextGreenBtn {
    width: 2rem;
    height: .5rem;
    padding: 0;
    border-radius: .08rem;
    /* background: #1a1a1a;
    color: white; */
    font-size: 0.3rem;
        margin-top: 0.3rem;
    margin-bottom: 0.4rem;
}
.ShippingScreen .form-check-label {
  font-size: 0.3rem; /* adjust font size as needed */
  display: inline-block;
  margin-left: 10px; /* adjust spacing as needed */
}

.ShippingScreen .form-check-input[type="radio"] {
  width: 25px; /* adjust size as needed */
  height: 25px; /* adjust size as needed */
  margin-top: 5px; /* adjust spacing as needed */
}
h2.jiesuantq {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.ShippingScreen .list-group-item .row {
    margin-left: 0;
    margin-right: 0;
}
.ShippingScreen .list-group-item p {
    margin-top: 0;
    margin: .16rem 0;
    letter-spacing: 1px;
    font-weight: 200;
    font-size: .3rem;
    color: rgb(85, 89, 92);
    /* margin-top: .16rem; */

}
.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0;
    font-size: .3rem;
}
.list-group-flush>.list-group-item {
    border-width: 0 0 1px;
    font-size: .3rem;
}
.apply_btn {
    border: 1px solid yellow;
    border-radius: 16px;
    padding: 4px 18px;
    transition: .3s;
    font-size: .5rem;
}
.ShippingScreen .TextGreenBtn.PayBtn {
    width: 3rem;
    height: 0.8rem;
    font-size: .34rem;
}

}
