.cartBtn {
    margin-top: 0.2rem !important;
    padding: 0.105rem 0.18rem !important;
}

.sidebarClose span {
    visibility: visible !important;
}

div.basketContent {
    padding: 20px !important;
}

div.basketContent + .basketContent {
    margin-top: 30px;
}

.quantityWrapper {
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    width: fit-content;
    padding: 0;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    height: 36px;
    user-select: none;
    overflow: hidden;
    background-color: white;
}

.quantityWrapper .quantityBtn {
    font-size: 0.16rem;
    background-color: white;
    cursor: pointer;
    font-weight: 400;
    color: #333;
    padding: 0 0.1rem;
}

.quantityWrapper .quantityInput {
    width: 0.3rem;
    text-align: center;
    border-style: solid;
    border-color: #e5e5e5;
    border-width: 0 1px;
    padding: 0 0.04rem;
    height: 100%;
}

.trash {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #5d9eab;
    font-size: 22px;
    cursor: pointer;
}

@media screen and (max-width:540px) {
    .quantityWrapper .quantityBtn {
        font-size: 0.25rem;
        padding: 0 0.15rem;
    }

    .quantityWrapper .quantityInput {
        width: 0.3rem;
        text-align: center;
        font-size: 16px;
        padding: 0 0.12rem;
    }
}
