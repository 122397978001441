.search-container {
    position: relative;
  }

  .search-box {
    display: flex;
    align-items: center;
    /*margin-bottom: 10px;*/
  }

  /*.search-box input[type="text"] {*/
  /*  padding: 10px;*/
  /*  font-size: 16px;*/
  /*  border: 1px solid #ccc;*/
  /*  border-radius: 4px;*/
  /*  width: 100%;*/
  /*}*/

  /*.search-box button {*/
  /*  background-color: #4CAF50;*/
  /*  color: #fff;*/
  /*  padding: 10px;*/
  /*  border: none;*/
  /*  border-radius: 4px;*/
  /*  margin-left: 10px;*/
  /*  cursor: pointer;*/
  /*}*/

  .search-box button:hover {
    background-color: #3e8e41;
  }

  .search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    max-height: 400px;
    overflow-y: auto;
  }

  .search-history {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .search-history h4 {
    font-size: 14px;
    margin-top: 0;
  }

  .history-item {
    padding: 5px 10px;
    cursor: pointer;
  }

  .history-item:hover {
    background-color: #eee;
  }

  .search-matches {
    padding: 10px;
  }

  .product {
    padding: 5px 10px;
    cursor: pointer;
  }

  .product:hover {
    background-color: #eee;
  }

  .product h3 {
    margin-top: 0;
    font-size: 18px;
    font-weight: bold;
  }

  .product p {
    margin-bottom: 0;
    font-size: 14px;
    color: #999;
  }

  .loading {
    padding: 10px;
    text-align: center;
    color: #999;
  }
