.shop-by-category {
  text-align: center;
  margin-top: 2rem;
}

.shop-by-category h2 {
  font-size: 1.25rem; /* increased for better visibility */
  font-weight: bold; /* consider using 600 or 700 depending on the font */
  margin-top: 0; /* removed negative margin to prevent overlap */
  margin-bottom: 1rem; /* adjusted for spacing */
  letter-spacing: 0.05em; /* added letter-spacing for style */
  color: #333; /* a darker color for better contrast against a light background */
}
.category-overlay-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2); /* semi-transparent black overlay */
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

/*.category:hover .category-overlay-img {*/
/*  opacity: 1;*/
/*}*/

.category-overlay-img .category-name {
  color: #fff; /* white text */
  font-size: 0.25rem;
  font-weight: bold;
  text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.5); /* add shadow for better visibility */
}

.category-list {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  margin-top: 1rem;
  flex-direction: row;
}
.category-list a {
  display: flex;
  width: 25%;
}

.category-list a.category-link-lg {
  width: calc(50% - 24px);
}

.category {
  display: flex;
  /*border-radius: 0.5rem;*/
  overflow: hidden;
  /*box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);*/
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
  width: 100%;
}

/*.category:hover {*/
/*  transform: translateY(-0.25rem);*/
/*}*/

.category img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.category h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

@media only screen and (max-width: 768px) {
  .shop-by-category h2 {
    font-size: 0.4rem;
    font-family: Arial, sans-serif;
    text-align: center;
    white-space: normal; /* change from nowrap to normal */
    max-width: 100%; /* add max-width property */
  }

  .category {
    width: 100%;
    margin-right: 0 !important;
  }

  .category img {
    height: 200px;
  }

  .category-list a {
    width: calc(50% - 6px);
  }
  .category-list a.category-link-lg {
    width: 100%;
  }
}
