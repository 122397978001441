.OrderScreen .SortBy {
    margin-right: 10px;
    font-size: 0.16rem;
}
.OrderScreen .basket-contents{
    padding: 0;margin: -0.2rem 0 0 0;
}
.OrderScreen  .err {
    width: 0.13rem;height: 0.13rem;cursor: pointer;
}
.OrderScreen .basket-formTwo-footer {
    font-size: 0.16rem;line-height: 0.2rem;
}
.OrderScreen .paging {
    flex-wrap: nowrap;flex-direction: row;
}
.OrderScreen .basket-content {
    padding-left: 0.1rem;padding-right: 0.1rem;align-items: center;
}
.OrderScreen .box1 {
	width: 1rem;height: 1rem;
	margin-right: .3rem;
	object-fit: contain;
}
.OrderScreen .flex-between .productInfo {
    align-items: flex-start;flex: 1;align-items: center;margin-left: 0.2rem;
}

.OrderScreen .textGreen {
    color: #3F757B;
}
.OrderScreen .nowPrice {
    color: #3F757B;font-weight: bold;flex: 0.6;
}
.OrderScreen .right{
	flex: 1;
	color: black;
}
.OrderScreen .topName{
	color: rgb(67,117,123);
	font-size: 0.34rem;
	font-weight: 600;
	flex: 1;
	text-align: left;
}
.OrderScreen .detail{
	color: #000;
	font-size: 0.14rem;
	margin-top: 0.2rem;
	line-height: 0.18rem;
	width: 70%;
}
.OrderScreen .selectItem{
	color: #000;
	font-size: 0.15rem;
	margin-top: 0.4rem;
}
/* .OrderScreen .selects{
	width: 200px;
	margin: 0.2rem 0;
	border-bottom: 2px solid #555;
	padding-bottom: 0.1rem;
	cursor: pointer;
	border-bottom: 2px solid #333;
} */
.OrderScreen .downPutOne{
	flex: 1;
}
.OrderScreen .labelItem {
	display: flex;
	align-items: center;
	font-size: 0.16rem;
}
.OrderScreen label{
	margin-right: 0.1rem;
	font-size: 0.16rem;
	/* margin-top: .4rem; */
}
.OrderScreen label:nth-last-of-type(1) {
	margin-right: 0;
}



.basket-btn{
	font-size: 0.12rem;
	background-color: rgb(67,117,123);
	color: #fff;
	padding: 0.13rem 0;
	font-weight: 600;
	margin: 0.1rem 0;
	border-radius: 0.12rem;
	cursor: pointer;
	text-align: center;
}

.pageTest{ 
	/* width: 1000px;  */
    height: 200px; 
}

.last,.first,.next,.prv{
	color: #000 !important;
}
.prv,.first,.last{
	display: none;
}
.prev {
	transform: rotate(180deg);
	margin-right: 0.3rem;
}
.pagingUl li{
	margin: 0 0.1rem;
	border-radius: 50% !important;
}

.OrderScreen .flex-between {
	align-items: center;
}
.OrderScreen .result-text {
	 color: #000000;
	 margin-right: 0.6rem;
	 /* position: absolute;
	 bottom: -60px;
	 right: 300px; */
}
.OrderScreen .result-text-mb {
	display: none;
}


li {
				list-style: none;
			}

			div.main-wrap {
				position: relative;
				margin: 0 auto;
			}

			div.main-wrap div.paging-content {
				width: 100%;
				height: auto;
			}

			div.main-wrap div.paging-content ul.pageItem {
				width: 100%;
				/* overflow: hidden; */
				display: none;
			}

			div.main-wrap div.paging-content ul.pageItem.active {
				display: block;
			}

			div.main-wrap div.paging-content ul.pageItem li {
				width: 100%;
				color: #fff;
				/* margin-left: -10px; */
			}

			div.main-wrap div.paging {
				position: absolute;
				bottom: -70px;
				right: 0;
				height: 40px;
				width: 600px;
			}

			div.main-wrap div.paging>span {
				display: block;
				width: 40px;
				height: 40px;
				cursor: pointer;
				float: left;
			}


			div.main-wrap div.paging span.next, div.main-wrap div.paging span.prev {
				margin-left: 10px;
				background: url(../img/index/right.png) center no-repeat;
				background-size: 12px 20px;
			}

			.OrderScreen div.main-wrap div.paging div.page-btn {
				width: 200px !important;
				height: 60px;
				margin-top: 16px;
				margin-left: -20px;
				overflow: hidden;
				float: left;
				position: relative;
			}

			div.main-wrap div.paging div.page-btn ul {
				width: 2000px;
				height: 40px;
				position: absolute;
				left: 0;
                top: 0;
                margin: 0;
			}

			div.main-wrap div.paging div.page-btn ul li {
				float: left;
				height: 40px;
				line-height: 40px;
				width: 40px;
				text-align: center;
				font-size: 20px;
				color: #666;
				cursor: pointer;
			}

			div.main-wrap div.paging div.page-btn ul li.active {
				color: #fff;
				background-color: rgb(67,117,123);
				border-radius: 50%;
			}

.OrderScree.radio-wrap {
	flex-wrap: wrap;justify-content: space-between;
}

.OrderScreen .basket-content-item > .flex-between > div:nth-of-type(2) {
	flex: 0.5 !important;
}
.OrderScreen .basket-content-item > .flex-between > div:nth-of-type(3) {
	flex: 0.6 !important;
}
.OrderScreen .basket-content-item > .flex-between > div:nth-of-type(5) {
	flex: 0.6 !important;
}
.OrderScreen .basket-content-item > .flex-between > div:nth-last-of-type(3) {
	flex: 1.3 !important;
}
.OrderScreen .basket-content-item > .flex-between > div:nth-last-of-type(1) {
	flex: 1.2 !important;
}




.basket-contents{
	/* margin: 0 15%; */
	margin-top: 0.3rem;
	margin-bottom: -0.7rem;
	position: relative;
	z-index: 2;
}
.basket-swiper-container {
	overflow: hidden;
	position: relative;
}
.basket-form{
	border: 2px solid rgb(67,117,123);
	color: #000;
	font-size: 0.16rem;
	background-color: #fff;
	margin-top: 0.2rem;
}
.basket-formTwo {
	display: none;
	width: 100%;
	/* height: 500px; */
	border: 2px solid rgb(67,117,123);
	margin-top: 0.4rem;
	background-color: #FFFFFF;
}
.basket-formTwo-title {
	width: 100%;
	height: 0.8rem;
	background-color: #3F757B;
	display: flex;
	align-items: center;
	padding: 8%;
	justify-content: space-between;
}
.basket-formTwo-title > img {
	width: 0.3rem;
	height: 0.3rem;
	object-fit: contain;
	display: block;
	cursor: pointer;
}
.basket-formTwo-title .dot, .swiper-pagination-bullet {
	width: 0.3rem;
	height: 0.3rem;
	border-radius: 50%;
	background-color: #87CAD2;
	cursor: pointer;
}
.basket-formTwo-title .dot.active {
	background-color: #E8F4F4;
}
.basket-formTwo-name {
	color: #333;
	font-size: 0.26rem;
	height: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 0.6rem;
	border-bottom: 2px solid rgb(67,117,123);
	/* border-top: 2px solid rgb(67,117,123); */

}
.basket-formTwo-content {
	padding: 0.4rem 0.4rem;
	color: #333;
	/* display: flex;
	align-items: center; */
}
.basket-formTwo-content > .flex {
	margin-bottom: 0.2rem;
}
 /* .basket-formTwo-content:nth-of-type(1) {
	padding-top: 0;
} */
.basket-formTwo-footer {
	width: 100%;
	height: 0.8rem;
	background-color: #3F757B;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.4rem;
	font-size: 0.25rem;
	font-weight: bold;
}
.basket-goods-img {
	width: 2.5rem;
	height: 2.5rem;
	object-fit: contain;
	margin-right: 0.4rem;
}
.basket-goods-img-odd {
	width: 2.5rem;
	height: 2.5rem;
	object-fit: cover;
	position: absolute;
	left: 0.4rem;
	top: 1.4rem;
	z-index: 99;
}
.basket-goods-desc {
	/* flex: 1; */
	font-size: 0.2rem;
	/* flex-shrink: 0; */
}

.basket-top{
	flex-direction: row-reverse;
	justify-content: space-between;
	padding: 0.1rem 13% 0.1rem 1.9rem;
	border-bottom: 2px solid rgb(67,117,123);
}
.payNow {
    background-color: rgb(255,192,8);
}
.basket-content{
	padding: 6% 0.4rem 6% 5%;
	font-size: 0.12rem;
}

.basket-money{
	color: rgb(67,117,123);
	font-weight: 600;
	font-size: 0.14rem;
}
.basket-foot{
	width: 100%;
	background-color: rgb(67,116,122);
	flex-direction: row-reverse;
	color: #fff;
	padding: 0.14rem 12% 0.14rem 0;
	font-size: 0.13rem;
}
.basket-content-item{
	margin-bottom: 0.2rem;
}
.basket-content-item > .flex-between > div:nth-of-type(1) {
	flex: 1.2;
	margin-right: 0.1rem;
}
.basket-content-item > .flex-between > div {
	flex: 1;
}
.OrderScreen .orderDetail {
	border: 2px solid rgb(67,116,122);
	margin-top: .2rem;
}
.OrderScreen .orderDetail {
	display:  none;
}
@media screen and (max-width:900px){
	.OrderScreen .orderDetail {
		display:  block;
	}
	.OrderScreen.right {
		width: 100%;
	}
	.OrderScreen table{
		display: none;
	}
	.basket-form{
		display: none;
	}
	.basket-formTwo {
		display: block;
	}
	.basket-contents{
		margin-left: 0.4rem;
		margin-right: 0.4rem;
		margin-bottom: -1.2rem;
	}
	
}

.OrderScreen .basket-top-time {
	display: flex;
	align-items: center;
    justify-content: space-between;
    background-color: rgb(67,117,123);
    color: #fff;
    font-size: 0.13rem;
    padding: 0 0.2rem;
    line-height: 0.34rem;
}
.OrderScreen tr td,.OrderScreen tr th{
	padding: 0.1rem .01rem;
	box-sizing: border-box;
}

.OrderScreen thead>tr:last-of-type th{
	padding:  0;
} 
.OrderScreen th {
	font-weight: normal;
}
.OrderScreen table {
	width: 100%;
	color: black;
	border-collapse: collapse;
	border: 2px solid rgb(67,117,123);
	/* border-left-width: 12px; */
	font-size: 0.16rem;
	text-align: left;
	margin-top: .26rem;
	box-sizing: border-box;
}
.OrderScreen td {
	font-size: 0.12rem;
}
@media screen and (max-width:640px){
	
	/* .accountScreen .OrderScreen .left,.OrderScreen .right{
		width: 100%;
	} */
}







@media screen and (max-width:1450px){

	.OrderScreen .selectItem{
		flex-direction: column;
	}
	/* ,.selects */
	.OrderScreen form{
		width: 100%;
		font-size: 0.2rem;
	}
	.OrderScreen label{
		margin-right: 0.3rem;
	}
	.OrderScreen .basket-btn {
		width: 1.3rem;
		font-size: .16rem;
	}
	
}
@media screen and (max-width:1100px){
	.OrderScreen .center{
		flex-direction: column;
	}
	
	.OrderScreen .right .title{
		display: none;
	}
	.OrderScreen .basket-content-right {
		left: 4.95rem;
	}
}

@media screen and (max-width:690px){
	.left .title ,.accountScreen  .name{
		font-size: 0.4rem !important;
		margin: 0.4rem 0;
		line-height: 0.4rem;
	}
	.accountScreen .name{
		margin: 0.5rem 0;
	}
	.accountScreen .welcome{
		font-size: 0.6rem;
	}
	.accountScreen .detail{
		font-size: 0.25rem !important;
		width: 90%;
		line-height: 0.34rem!important;
	}
	.accountScreen label{
		margin-top: 0.1rem;
		font-size: 0.25rem;
	}
	.accountScreen .selects > div {
		font-size: 0.25rem !important;
	}
	.accountScreen .radio-wrap {
		justify-content: center;
		flex-wrap: wrap;
	}
	.accountScreen .form{
		display: flex !important;
		align-items: center!important;
		justify-content: center!important;
		flex-wrap: wrap!important;
		font-size: 0.3rem !important;
	}
	/* .left{
		font-size: 0.22rem !important;
		line-height: 0.36rem !important;
	} */
	.accountScreen .downPutOne{
		font-size: 0.2rem;
	}
	.accountScreen .center{
		flex-direction: column;
	}
	.accountScreen .OrderScreen .left{
		width: 100%;
		font-size: 0.16rem;
		line-height: 0.3rem;
	}
	.OrderScreen .title,.OrderScreen .name{
		font-size: 0.28rem;
	}
	.accountScreen .topName{
		font-size: 0.44rem;
		padding: 0 0.3rem;
		flex-direction: column;
		align-items: flex-start;
	}
	.accountScreen .detail{
		font-size: 0.2rem;
		margin: 0.5rem 0.2rem;
		line-height: 0.26rem;
	}
	.accountScreen .pageTest {
		width: auto;
	}
	.accountScreen .result-text {
		margin-right: 0;
		margin-top: 0.5rem;
		margin-bottom: -0.3rem;
		font-size: 0.25rem;
	}
	.accountScreen .paging {
		flex-direction: column;
		align-items: center;
		left: 50%;
		transform: translate(-50%, 0);
	}
	.accountScreen .result-text-mb {
		display: block;
		text-align: center;
	}
	.accountScreen .result-text-pc {
		display: none;
	}
}