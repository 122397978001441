/* Slider Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background: transparent;
  z-index: 1;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev i,
.slick-next i {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 20px;
  line-height: 1;
  color: #000;
}

.slick-prev:hover i,
.slick-next:hover i {
  color: #fff;
}

.slick-prev:active i,
.slick-next:active i {
  transform: scale(1.1);
}

.slick-disabled {
  pointer-events: none;
  opacity: 0.3;
}
