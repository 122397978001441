
.login .title{
	color: rgb(67,117,123);
	font-weight: 600;
	font-size: 0.32rem;
	margin: 0.4rem 0 0.24rem;
}
.login .content{
	color: #000;
	width: 50%;
	font-size: 0.15rem;
	line-height: 0.2rem;
	margin-bottom: 0.7rem;
}
.login .secction{
	color: rgb(67,117,123);
	padding: 0.7rem 0.4rem;
	align-items: flex-start;
}
.login .secction.regBox {
	
	padding: 1.5rem 15% 1rem 15%;
}
.login .left{
	width: 50%;
	flex: 1 1 50%;
	margin: 0 0.4rem;
}
.login .name{
	font-size: 0.36rem;
	font-weight: 600;
	color: rgb(67,117,123);
}
.login .cont{
	font-weight: 600;
	font-size: 0.2rem;
	margin: 0.08rem 0 0.6rem 0;
}
.login .uil{
	color: #000;
	font-size: 0.14rem;
}
.login .place {
    color: #000;
    font-size: 0.14rem;
    margin: 0.2rem 0;
}
.login .registerBtn {
    background-color: rgb(67,117,123);
    color: #fff;
    text-align: center;
    margin: 0.34rem 0 0.2rem 0;
    padding: 0.1rem 0;
    border-radius: 0.12rem;
    font-size: 0.16rem;
    cursor: pointer;
}
.login .uil input{
	background-color: rgb(232,244,244);
	flex: 1;
}
.login .lis{
	border-bottom: 1px solid #000;
	flex: 1;
	padding-bottom: 0.1rem;
	margin-top: 0.14rem;
}
.login .right{
	width: 50%;
	flex: 1 1 50%;
	margin: 0 0.4rem;
}
.login .loginBtn{
	color: #fff;
	background-color: rgb(67,117,123);
	text-align: center;
	font-size: 0.15rem;
	margin: 0.3rem 0 0.16rem 0;
	padding: 0.12rem 0;
	border-radius: 0.13rem;
    cursor: pointer;
    margin-bottom: 0.1rem;
}
.login .botm{
	font-size: 0.14rem;
	color: #000;
}
.login .here{
	color: rgb(67,117,123);
	margin-left: 0.06rem;
	cursor: pointer;
}
.login .tips {
	/* position: absolute; */
	/* right: -2.5rem;
	top: 0.7rem; */
	color: #333;
	/* width: 2.5rem; */
	/* transform: translate(-50%, 0); */
	text-align: center;
	margin-top: -0.1rem;
	padding-bottom: 0.5rem;
}
.login .padLR {
    padding: 0 0.5rem;
}
.login .sec2 {
    background-color: rgb(232,244,244);position: relative;
}
.login .sec2>.secction {
    position: relative;
}
.login .minCar {
    width: 0.35rem;
    height: 0.25rem;
    margin-right: 0.2rem;
}
.login .liItem {
    cursor: pointer;
}
.login .uil {
    font-weight: 600;
    align-items: flex-end;
}
.login  .whySignUp {
	width: 100%;
	color: rgb(67,117,123);
	padding: 0.6rem 8% 0.2rem 8%;
	background: white;
}
.login .why {
    font-size: 0.3rem;
    font-weight: 600;
	margin-bottom: 0.06rem;
}
.login .account {
    font-weight: 600;
    margin-bottom: 0.6rem;
}
.login .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.login .or {
	display: none;
}
@media screen and (max-width:990px){
	.login .or {
		width: 100%;
		display: flex;
		align-items: center;
		margin: 0.8rem 0 1.7rem 0;
	}
	.login .or span {
		margin: 0 10px;
	}
	.login .or::before, 
	.login .or::after {
		content: ' ';
		background: rgb(67,117,123);
		height: 1px;
		flex: 1 1 100%;
	}
	.login .name:not(.loginName) ,
	.login .title,
	.login .place,
	.login .content,
	.login .cont{
		display: none;
	}
	.login a {
		color: inherit;
	}
	.login .registerBtn {
		background: none;
		color: rgb(67,117,123);
		border: 1px solid #999;
	}
	.login .secction {
		flex-wrap: wrap;
		flex-direction: column;
	}
	.login .secction.regBox {
		flex-direction: column-reverse;
	}
	.login .left, .login  .right {
		width: 100%;
		margin: 0;
	}
	.login .account {
		font-size: 0.44rem;
		width: 70%;
		line-height: 0.58rem;
		margin-top: 0.1rem;
	}
	.login .title{
		font-size: 0.7rem;
		line-height: 0.9rem;
		margin: 0.5rem 0 0.4rem 0;
	}
	.login .content{
		width: 90%;
		font-size: 0.28rem;
		line-height: 0.32rem;
	}
	.login .name{
		font-size: 0.7rem;
		line-height: 0.7rem;
	}
	.login .cont{
		font-size: 0.4rem;
		width: 50%;
		line-height: 0.5rem;
	}
	.login .lis{
		font-size: 0.27rem;
		margin-top: 0.4rem;
		padding-bottom: 0.2rem;
		width: 100%;
	}
	.login .loginBtn, .login .registerBtn{
		font-size: 0.3rem;
		font-weight: 600;
		padding: 0.24rem 0;
		border-radius: 0.25rem;
		margin: 0.5rem 0 0.2rem 0;
        font-family: Arial, sans-serif;
	}
	.login .botm {
		font-size: .2rem;
	}
	
	.login .contTwo{
		width: 60%;
	}
	.login .two{
		flex-direction: column;
		flex: 1;
	}
	.login .uil input{
		font-size: 0.2rem;
	}
	.login .minCar {
		width: 0.66rem;
		height: 0.5rem;
	}
	.login .uil {
		flex-direction: column;
		align-items: flex-start;
		font-size: 0.4rem;
	}
	.login .liItem {
		margin-bottom: 0.88rem;
	}
	.login .why {
		font-size: 0.7rem;
		margin-top: 0.8rem;
	}
}
@media screen and (max-width:540px){
	.login .name{
		font-size: 0.7rem;
		line-height: 0.7rem;
		    font-family: Arial, sans-serif;
    text-align: center;
font-weight: bold;
	}
	.login .uil input{
		font-size: 0.3rem;
	}
	.login .botm {
		font-size: .24rem;
	}
    .login .or span {
		margin: 0 15px;
		font-size: 0.3rem;
	}

}