.OrderDetailScreen {
    font-family: "Nunito Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color: black;
    padding: 0 0.5rem;
}
.OrderDetailScreen h1 {
    padding: .16rem 0;
    font-size: .32rem;
    font-weight: 600;
    color: rgb(26, 26, 26);
    margin-bottom: 0.08rem;
}
.OrderDetailScreen h2 {
    padding: .08rem 0;
    font-size: .28rem;
    font-weight: 600;
    color: rgb(85, 89, 92);
    margin-bottom: 0.08rem;
}
.OrderDetailScreen p, .OrderDetailScreen strong {
    color: #1a1a1a;
    letter-spacing: 1px;
    font-weight: 200;
    font-size: .14rem;
    margin-bottom: .16rem;
}
.OrderDetailScreen .col-md-8, .OrderDetailScreen .col-md-4 {
    padding: 0 .15rem;
}
.OrderDetailScreen .alert {
    position: static;
    padding: .12rem .2rem;
    margin-bottom: .16rem;
    border: 1px solid transparent;
}
.OrderDetailScreen a,.OrderDetailScreen .col-md-4>div{
    height: 100% ;
    display: flex;
    align-items: center;
    color: inherit;
}
@media screen and (max-width: 1070px) {
    .OrderDetailScreen .row {
        width: 100% !important;
        margin-left: 0;
        margin-right: 0;
    }
    .OrderDetailScreen .col-md-8, 
    .OrderDetailScreen .col-md-4 {
        width: 100%;
        max-width: 100%;
        flex: 1 1 100%;
        padding: 0;
    }
    .OrderDetailScreen .alert {
        width: 100%;
    }
}
