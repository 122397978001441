.bgColors {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.bgMobileColors {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  overflow-x: auto;
}

.bgColor {
  width: 50px;
  height: 50px;
  border-radius: 0.06rem;
  border: 2px solid #000;
  cursor: pointer;
}

.bgMobileColor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  flex-shrink: 0;
}

.activeBgColor {
  border: 3px solid #ffc008;
}
