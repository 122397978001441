/* Styling for the sorting UI */
.ul-title-sort {
    display: flex;
    align-items: center;
    margin: 1rem 0;
}

.sort-label {
    font-size: 0.2rem;
    margin-right: 0.2rem;
      font-weight: bold;
  color: #444444;
  text-transform: uppercase;
}

.sort-selector {
    padding: 0.1rem;
    font-size: 0.2rem;
    border: none;
    background-color: #f5f5f5;
    color: #333;
    border-radius: 0.3rem;
    cursor: pointer;
    width: 200px;
}

.sort-selector:hover {
    background-color: #e9e9e9;
}

.sort-selector:focus {
    outline: none;
    box-shadow: 0 0 0 2px #999;
}


.home .swiper-wrapper {

    position: relative;
    margin-bottom: 30px;
}
.relative:hover .swiper-button-prev,
.relative:hover .swiper-button-next {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.relative .carui_box:hover .carUi {
    transform: scale(1.1);
    transition: transform 0.5s ease-in-out;
}

.relative .swiper-button-prev:hover,
.relative .swiper-button-next:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
}
.home .ulBox:hover .btnLeft,
.home .ulBox:hover .btnRight {
  opacity: 1;
  /*transform: translateY(-5px);*/
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
}
/* 按钮hover */
/* CSS for laptop version only */
@media (min-width: 1024px) {
  .home .ulBox:hover .btnLeft,
  .home .ulBox:hover .btnRight {
    /*transform: translateY(-3px);*/
    box-shadow: none;
    transition: all 0.2s ease-in-out;
  }

  .home .btnLeft,
  .home .btnRight {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .home .ulBox:hover .btnLeft,
  .home .ulBox:hover .btnRight {
    opacity: 1;
  }

  .home .btnImg:hover,
  .home .btnImg:focus {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
  }
}

@media (max-width: 1024px) {
	.home .uls .ulBox {
		text-align: center;
		width: 32%;
		margin: 0 1% 0.5rem 0;
	}
}

/* 按钮hover */

/* 图片hover */
.home .ulBox img {
  transition: all 0.2s ease-in-out;
}

.home .ulBox:hover img {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
  /*box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);*/
}

/* 图片hover */
.home .swiper-slide {

	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.home .swiper-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	/*background-color: rgba(0, 0, 0, 0.5);*/
}
.home .swiper-button::after {
	content: '';
}
.home .swiper-button-prev {
	left: -60px;
}
.home .swiper-button-next {
	right: -60px;
}
.home .swiper-button img {
	height: 60%;
}
.home .car{
    width: 100%;
    height: 5rem;
    background: url(https://auto-clean.s3.eu-west-2.amazonaws.com/IMG_9195.JPG) no-repeat;
    background-size: cover;
    /*border-bottom-right-radius: 2.1rem;*/
    /*border-bottom-left-radius: 2.1rem;*/
    position: relative;
}

.home .content{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
}
.home .title {
	cursor: pointer;
    font-family: AktivGrotesk, serif;
    font-size: 68px;
    font-weight: 600;
    font-style: italic; /* 文字倾斜 */
    color: rgba(255, 255, 255, 0.8); /* 白色字体，80% 不透明度 */
    text-align: center;
    margin-top: 50px;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5); /* 4px 位移，8px 模糊度，50% 不透明的黑色阴影 */
    word-wrap: break-word; /* 在必要时断行 */
    overflow-wrap: break-word; /* 在必要时断行 */
    max-width: 90%; /* 限制标题最大宽度为父容器宽度的90% */
    margin-left: auto; /* 居中 */
    margin-right: auto; /* 居中 */
    position: relative; /* 为了使用伪元素 */
    line-height: 1.2; /* 调整行高以更紧密控制断行 */
}

.home .title:before,
.home .title:after {
  content: '';
  display: block;
  height: 3px;
  width: 70%;
  margin: 0 auto;
  background-color: #fff;
  opacity: 0.5;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.home .title:before {
  margin-bottom: 10px;
}

.home .title:after {
  margin-top: 10px;
}


.home .shop-by-category h2 {
	font-size: 0.3rem;
	margin-bottom: 0.5rem;
	font-family: AktivGrotesk, sans-serif;
}
.home .shop-by-category {
	margin-top: 0.5rem;
}
.home .category-list {
	margin-top: 0.5rem;
}



.home .cont{
	font-size: 0.15rem;
	width: 45%;
	margin: 0.2rem 0;
	line-height: 0.2rem;
}
.home .btn{
	background-color: #3F757B;
	font-size: 0.15rem;
	width: 1.5rem;
	padding: 12px 0;
	border-radius: 10px;
	text-align: center;
	cursor: pointer;
}
.home .ul-title{
	color: #3F757B;
	margin: 0.5rem 0 0.4rem 0.6rem;
	font-weight: bold;
    font-stretch: normal;
    font-size: 30px;
	font-family: AktivGrotesk, sans-serif;
}
.home .uls{
	flex-wrap: wrap;
	width: 100%;
	padding-left: 0.6rem;
}
.home .ulBox{
	text-align: center;
	width: 20%;
	margin: 0 5% 0.5rem 0;
}
.home .ulBox>a>img{
	width: 100%;
	height: 202px;
	object-fit: cover;
}

.home .money{
	color: #747A81;
	font-size: 0.18rem;
	font-weight: 600;
	margin: 0.08rem 0 0.1rem 0;
	display: flex;
	flex-direction: row-reverse;
	/*width: 100%;*/
	justify-content: space-between;
	align-items: center;
}
.home .btnImg{
	width: 0.22rem;
	height: 0.18rem;
	margin-right: 0.03rem;
}
.home .btnLeft{
	margin-right: 8%;
	padding: 0.1rem;
	border-radius: 0.06rem;
	background-color: #3F757B;
	cursor: pointer;
	font-family: Arial, sans-serif;
}
.home .btnRight{
	padding: 0.1rem;
	border-radius: 0.06rem;
	background-color: rgb(255, 192, 8);
	cursor: pointer;
	color: white;
}
.home .swiperTitle{
	color: #4C747A;
	font-weight: 600;
	margin: 0.4rem 0 0.4rem;
	font: normal normal bold 30px/38px AktivGrotesk;

}

.home .swiper-containerFour,
.home .swiper-containerThree,
.home .swiper-containerTwo,
.home .swiper-containerOne {
	/* width: calc(100% - 1.2rem); */
	width: 100%;
	height: 320px;
	position: relative;
	margin-bottom: 0.56rem;
	margin: 0 auto;
	overflow: hidden;
}
.home .swiper-containerThree,
.home .swiper-containerTwo,
.home .swiper-containerOne {
	display: none;
}
.home .swiperImg{
	height: 0;
	width: 100%;
	padding-top: 108%;
	position: relative;
	overflow: hidden;
}
.home .swiperImg > img {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	object-fit: cover;
	transform: scale(1);
	transition: all 0.3s ease-in-out;
}
.home .swiperImg:hover > img {
	transform: scale(1.2);
}
.home .name{
	display: flex;
	align-items: flex-end;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	/*background-color: rgb(92,133,139);*/
	padding: 0.2rem 0;
	height: 100%;
	font-size: 0.16rem;
	font-weight: 600;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(92,133,139, 0.7) 100%);
	transition: all 0.3s ease-in-out;
}
.home .name:hover{
	font-size: 0.18rem;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%, rgba(92,133,139, 0.7) 100%);
}
.home .inStock,
.home .notInStock {
	display: flex;
	align-items: center;
	font-size: 0.13rem;
	margin-left: 0.1rem;
	font-weight: bold;
}
.home .inStock img, .home .notInStock img{
	width: 0.15rem;
	height: 0.15rem;
	margin-right: 0.05rem;
}
.home .flex-center {
	display: flex;
	align-items: center;
	justify-content: center;

}

.home .goods-price {
	font-weight: bold;
	margin-top: 0.2rem;
}
.home .del-price {
    color: #3F757B;text-decoration: line-through;
}
.home .now-price {
    color: #FFC008;margin-left: 0.2rem;
}
@media screen and (max-width:935px){
	.home .ulBox{
		text-align: center;
		/*width: 30%;*/
		/*margin: 0 2% 0.5rem 0;*/
	}
	.home .cont{
		font-size: 0.14rem;
		width: 70%;
		margin: 0.2rem 0;
	}
	.home .car{
		/*border-bottom-right-radius: 1.5rem;*/
		/*border-bottom-left-radius: 1.5rem;*/
	}

	.home .money span {
		color: #FFC008;
		margin-left: 0.2rem;
	}
	.home .uls{
		flex-wrap: wrap;
		width: 100%;
		margin: 0 auto;
		padding-left: 0.4rem;
	}
	.home .swiper-containerFour,
	.home .swiper-containerTwo,
	.home .swiper-containerOne{
		display: none;
	}
	.home .swiper-containerThree{
		display: block;
		height: 320px;
	}
}
@media screen and (max-width:640px){
	.home .uls .ulBox {
		text-align: center;
		width: 47%;
		margin: 0 1% 0.5rem 0;
	}
	.home .imgs {
		height: 6.4rem !important;
		position: relative;
	}
	.home .ulBox{
		text-align: center;
		/*width: 48%;*/
		/*margin: 0 1.5% 0.5rem 0;*/
	}
	.home .cont{
		font-size: 0.14rem;
		width: 90%;
		margin: 0.2rem 0;
	}
	.home .car{
		border-bottom-right-radius: 0rem;
		border-bottom-left-radius: 0rem;
	}
	.home .content.absolute{
		position: absolute;
		/*left: 6%;*/
		/*top: 10%;*/
	}
	.home .uls{
		flex-wrap: wrap;
		width: 100%;
		margin: 0 auto;
		padding-left: 0.2rem;
	}
	.home .swiper-containerFour,
	.home .swiper-containerThree,
	.home .swiper-containerOne{
		display: none;
	}
	.home .swiper-containerTwo{
		display: block;
		height: 350px;
	}
	.home .cart-sidebar {
		width: 100%;
	}
}
.home .black {
 color: black;
}
@media screen and (max-width:540px){


    .sort-label {
        font-size: 0.3rem;
        margin-right: 0.2rem;
        margin-left: 0.2rem;

    }
    .sort-selector {
    padding: 0.2rem;
    font-size: 0.3rem;
    border: none;
    background-color: #f5f5f5;
    color: #333;
    border-radius: 0.3rem;
    cursor: pointer;
    width: 200px;
}
	.home .car{
		height: 3.8rem;
	}
	.home .swiperTitle{
		text-align: center;
		font-size: 0.5rem;
		margin: 0.4rem 0 0.7rem 0;
	}

	.home .content{
		/*top: 17%;*/
	}

    .home .title {
        font-family: 'Helvetica Neue', sans-serif;
        font-size: 40px; /* 移动端字体大小设置为40px */
        font-weight: 600;
        color: rgba(255, 255, 255, 0.8); /* 白色字体，80% 不透明度 */
        text-align: center;
        margin-top: 0;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5); /* 3px 位移，6px 模糊度，50% 不透明的黑色阴影 */
        word-wrap: break-word; /* 在必要时断行 */
        overflow-wrap: break-word; /* 在必要时断行 */
        max-width: 100%; /* 移动设备下标题最大宽度为父容器宽度 */
        margin-left: auto; /* 居中 */
        margin-right: auto; /* 居中 */
    }

.home .title:before,
.home .title:after {
  content: '';
  display: block;
  height: 3px;
  width: 70%;
  margin: 0 auto;
  background-color: #fff;
  opacity: 0.5;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.home .title:before {
  margin-bottom: 10px;
}

.home .title:after {
  margin-top: 10px;
}

	.home .cont{
		font-size: 0.28rem;
		width: 90%;
		margin: 0.3rem 0;
		line-height: 0.34rem;
	}
	.home .btn{
		font-size: 0.23rem;
		font-weight: 600;
		width: 2.6rem;
		padding: 0.23rem 0;
		border-radius: 0.26rem;
	}
    .home .ulBox .flex-center .btnLeft {
        display: none;
    }
    .home .ulBox {
		display: flex;
		flex-direction: column;
      text-align: center;
      width: calc(50% - 10px);
		margin: 0;
      /*border: 5px solid #e5e5e5;*/
      border-radius: 5px;
      padding: 0.2rem;
      position: relative;
    }

    .home .ulBox:hover {
      border-color: #fca311;
    }

    .home .ulBox .flex-center .btnLeft:hover,
    .home .ulBox .flex-center .btnRight:hover {
      background-color: #fca311;
      color: #ffffff;
      cursor: pointer;
    }

    .home .ulBox .flex-center .btnImg {
      width: 1.5rem;
      margin-right: 0.5rem;
    }

.home .ul-title {
  font-size: 0.5rem;
  margin: 2rem auto 20px; /* add 20px margin-bottom and remove margin-top */
  text-align: center;
  white-space: normal; /* change from nowrap to normal */
  max-width: 100%; /* add max-width property */
  margin-top: 1rem;
}


	.home .uls{
		width: 100%;
		margin: 0 auto;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: stretch;
		gap: 10px;
	}
    .home .ulBox .money > span {
        font-family: Arial;
        font-weight: bold;
        font-size: 0.35rem;
		margin-left: 0 !important;
    }
	.home .money{
      font-size: 0.3rem;
      display: flex;
      justify-content: center;
		/*margin: 0.08rem 0 0.5rem 0;*/
        font-family: Arial, sans-serif;
	}
	.home .p-title {
		flex: 1;
	}
	.home .btnLeft{
		margin-right: 5%;
		padding: 0.24rem 0;
		font-size: 0.34rem;
		border-radius: 0.3rem;
	}

	  .home .ulBox .flex-center .btnRight div {
    font-family: Arial;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .home .ulBox .flex-center .btnRight {
    padding: 0.5rem;
    border: 2px solid #fca311;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home .ulBox .flex-center .btnRight:hover {
    background-color: #fca311;
    color: #ffffff;
  }

.home .ulBox .flex-center .btnRight::before {
    /*content: "ADD TO CART";*/
    /*font-size: 0.2rem;*/
    /*font-weight: bold;*/
    /*font-family: Arial;*/
    /*text-transform: uppercase;*/
    /*white-space: nowrap;*/
    /*color: #ffffff;*/
    /*padding: 0.2rem;*/
}
	.home .btnImg{
		width: 0.55rem;
		height: 0.5rem;
	}

	.home .swiper-containerFour,
	.home .swiper-containerThree,
	.home .swiper-containerTwo{
		display: none;
	}
	.home .swiper-containerOne {
		display: block;
		height: 565px;
	}
	.home .name{
		font-size: 0.38rem;
	}
    .home .ulBox .flex-center .btnRight .btnImg {
        display: none;
    }
    .home .ulBox .flex-center .btnRight div {
    display: none;
    }
    .home .ulBox .flex-center .btnRight {
		padding: 0.12rem;
		font-size: 0.3rem;
}

	.home .inStock img, .home .notInStock img{
		width: 0.25rem;
		height: 0.25rem;
	}

	.home .swiper-button-prev {
		left: -45px;
	}
	.home .swiper-button-next {
		right: -45px;
	}
	.home .swiper-button {
		width: 0.7rem;
		height: 0.7rem;
	}
	.home .discover-more-carousel {
		margin: 0 auto;
		width: 80%;
	}
}
