
.Describe .topTitle{
	margin: 0.5rem 0 0.2rem 0.5rem;
	color: rgb(76,117,123);
	font-weight: 600;
	font-size: 0.3rem;
}
.Describe .topCont{
	color: #000;
	margin: 0 0 0.6rem 0.5rem;
	font-size: 0.16rem;
	width: 50%;
}
.Describe .center{
	padding: 3% 0 5% 5%;
	color: #000;
	font-size: 0.15rem;
}
.Describe .content{
	width: 40%;
	margin-right: 6%;
}
.Describe .lis{
	border-bottom: 2px solid #555;
	padding-bottom: 0.1rem;
	margin-top: 0.3rem;
}
.Describe .iput{
	width: 100%;
	background-color: rgb(232,244,244);
	font-size: 16px;
	font-family: JosefinSans-Regular;
}
.Describe textarea.iput {
	border: none;
	outline: none;
	resize:none;
}
.Describe .sub{
	margin-top: 0.3rem;
	color: #fff;
	background-color: rgb(63,117,123);
	padding: 0.11rem 0;
	width: 36%;
	border-radius: 0.1rem;
	text-align: center;
	cursor: pointer;
}
.Describe .one{
	font-weight: 600;
	margin-bottom: 0.2rem;
}


.Describe .secctBtn {
	flex-direction: column;
	padding: 5% 0;
}

.Describe .btns {
	font-size: 0.15rem;
	background-color: rgb(63, 117, 123);
	margin-top: 0.3rem;
	padding: 0.1rem 0.38rem;
	border-radius: 0.1rem;
	cursor: pointer;
}
.Describe .title{
	font-size: 0.25rem;
	font-weight: 600;
}
.Describe p {
    margin: 1em 0;
}
.Describe ul,.Describe ol {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px;
}
.Describe ul li {
    list-style-type: disc;
}
.Describe ol li{
    list-style-type: decimal;
}
.Describe h2 {
    text-transform: none;
    margin: 0.83em 0;
}
.Describe ul.no-padding, .Describe ul.no-padding li {
    padding-inline-start: 0;
    list-style-position: inside;
}
@media screen and (max-width:950px){
	.Describe .topTitle{
		font-size: 0.4rem;
	}
	.Describe .topCont{
		font-size: 0.2rem;
		width: 70%;
	}
	.Describe .center{
		font-size: 0.2rem;
	}
	.Describe .content{
		width: 50%;
	}
}
@media screen and (max-width:560px){
	.Describe .topTitle{
		margin: 0.6rem 0 0.5rem 0.6rem;
		font-size: 0.64rem;
	}
	.Describe .topCont{
		font-size: 0.28rem;
		width: 76%;
		line-height: 0.34rem;
	}
	.Describe .center{
		flex-direction: column;
		align-items: flex-start;
		padding: 3% 0.56rem;
	}
	.Describe .content{
		width: 88%;
		margin: 0;
		font-size: 0.28rem;
	}
	.Describe .lis{
		margin: 0.6rem 0;
		padding-bottom: 0.24rem;
	}
	.Describe .sub{
		margin-top: 0.4rem;
		line-height: 0.5rem;
		width: 100%;
		font-weight: 600;
		border-radius: 0.26rem;
	}
	.Describe .care{
		font-size: 0.28rem;
		margin: 0.7rem 0;
		line-height: 0.34rem;
	}
	.Describe .one{
		padding: 0.2rem 0;
	}
	.Describe .secctBtn {
		padding: 15% 0;
	}
	.Describe .title{
		font-size: 0.7rem;
		width: 70%;
		line-height:0.9rem;
		text-align: center;
	}
	.Describe .btns {
		font-size: 0.3rem;
		margin-top: 0.5rem;
		font-weight: 600;
		width: 80%;
		text-align: center;
		padding: 0.27rem 0;
		border-radius: 0.3rem;
	}
}
